import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import Breadcrumbs from '../component/breadcrumbs'
import Alert from '../component/alert/'
import DocumentFilter from '../container/document-fixed-filter'
import { Tab, TabPanel } from '../container/document-tab'
import CompanyDocuments from '../container/company-documents'
import Loading from '../component/loading'
import SearchFilter from '../container/input-search-invoices'
import CompanyBankInformation from '../container/company-bank-information'

const getSelects = (state) => get(state, 'getFilters.selects', [])
const getStatus = (state) => get(state, 'getFilters.status', [])
const getLoading = (state) => get(state, 'getFilters.loading', true)

const Invoices = () => {
  const { t } = useTranslation()
  const selects = useSelector(getSelects)
  const status = useSelector(getStatus)
  const loadingStatus = useSelector(getLoading)

  const [showAlert, setShowAlert] = useState(true)
  const [inputSearch, setInputSearch] = useState('')

  const onAlertClick = (status) => {
    setShowAlert(status)
  }

  return (
    <div>
      <div className='i-container'>
        <Breadcrumbs title={t('breadcrumbs-title-invoices')} />
      </div>

      <DocumentFilter setInputSearch={setInputSearch} />

      {/* <div className='i-container'>
        <Alert
          showAlert={showAlert}
          onAlertClick={onAlertClick}
          type='danger'
          closable={true}
          title={t('alert-disclaimer-title')}
          message={t('alert-disclaimer-menssage')}
          email='accounts.receivable@embraer.com.br'
        />
      </div> */}

      <div className='i-container'>
        <Alert
          showAlert={showAlert}
          onAlertClick={onAlertClick}
          type='warning'
          closable={true}
          title={t('alert-invoices-title')}
          message={t('alert-invoices-menssage')}
        />
      </div>

      <SearchFilter inputSearch={inputSearch} setInputSearch={setInputSearch} />

      <Tab activeChange={true}>
        <TabPanel label={t('tabe-label-open')} index={0}>
          <div className='i-container'>
            {loadingStatus ? (
              <Loading />
            ) : (
              <CompanyDocuments selects={selects} status={status} />
            )}
          </div>
        </TabPanel>

        <TabPanel label={t('tabe-label-closed')} index={1}>
          <div className='i-container'>
            {loadingStatus ? (
              <Loading />
            ) : (
              <CompanyDocuments selects={selects} status={status} />
            )}
          </div>
        </TabPanel>

        <TabPanel label={t('tabe-label-bank')} index={2}>
          <div className='i-container'>
            <CompanyBankInformation />
          </div>
        </TabPanel>
      </Tab>
    </div>
  )
}

export default Invoices
